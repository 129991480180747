<template>
  <v-avatar :color="`hsl(${profile.color}deg,70%,70%)`">
    <v-img :src="require(`@/assets/images/avatars/${profile.avatar}`)" alt="avatar" />
  </v-avatar>
</template>

<script>
export default {
  props: ['profile']
}
</script>
