<template>
  <v-app>
    <div class="custom-row bg-white" :style="pigMode ? 'background: url(\'/PigBG.svg\') repeat;background-size: 50%;' : ''">
      <Navigation class="nav" v-if="
        !$route.meta.hideNav &&
        store.profile &&
        !store.profile.banned &&
        ((config && config.shutdown && !config.shutdown.isShutdown) || store.profile.dev)
      " :currentOrga="currentOrga" />
      <v-main>
        <div class="grid-col">

          <span>
            <div class="pa-3 d-flex align-center gap-3" elevation="0"
              v-if="!$route.meta.hideNav && store.profile && !store.profile.banned && ((config && config.shutdown && !config.shutdown.isShutdown) || store.profile.dev)"
            >
              <v-spacer />
              <OrganisationSelector :current="currentOrga" :organisations="organisations" />
              <h3 :class="'d-flex align-center gap-1 ' + (store.profile.role == 'SuperAdmin' ? 'pointer' : '')" v-if="currentOrga && isAdmin()" @click="goToStore()">
                {{ currentOrga.coins }}
                <v-icon>
                  <v-img :src="require(`@/assets/images/LP-Coin.svg`)" />
                </v-icon>
              </h3>
              <UserMenu />
            </div>
          </span>

          <div class="h-100 pa-6 app">
            <Shutdown v-if="config && config.shutdown && config.shutdown.isShutdown && (!store.profile || !store.profile.dev)" :config="config" />
            <Banned v-else-if="store.profile && store.profile.banned" />

            <router-view v-slot="{ Component }" v-else-if="
              (!$route.meta.needAccount || store.profile) // need accound
              && (!$route.meta.needOrga || currentOrga) // need orga
              && (!$route.meta.needAdmin || (store.profile && currentOrga && isAdmin())) // need admin
              && (!$route.meta.authLevel || store.profile && (($route.meta.authLevel[store.profile.role]) || ($route.meta.authLevel.Dev && store.profile.dev))) // need specific role
            ">
              <transition name="route" mode="out-in">
                <component :is="Component" :currentOrga="currentOrga" :config="config" :prices="prices" />
              </transition>
            </router-view>

            <NoOrga v-else-if="store.profile && !store.profile.organisations" />
            <div v-else class="h-100 d-flex flex-column align-center justify-center">Chargement...</div>
          </div>

          <Footer />
        </div>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { getAuth } from '@firebase/auth'

import { useProfileStore } from '@/stores/profile'
import { useArchivingStore } from '@/stores/archiving'
import Profile from '@/classes/Profile'
import Organisation from '@/classes/Organisation'
import Config from '@/classes/Config'
import { CoinsPrice, AppPrice } from '@/classes/Price'

import Navigation from '@/components/layout/navigation/Navigation.vue'
import UserMenu from '@/components/user/UserMenu.vue'
import OrganisationSelector from '@/components/OrganisationSelector.vue'
import Footer from '@/components/layout/Footer.vue'
import Banned from '@/views/errors/Banned.vue'
import NoOrga from '@/views/errors/NoOrga.vue'
import Shutdown from '@/views/errors/Shutdown.vue'

export default {
  components: {
    Navigation,
    UserMenu,
    OrganisationSelector,
    Footer,
    Banned,
    Shutdown,
    NoOrga
  },
  data() {
    return {
      store: useProfileStore(),
      archivingStore: useArchivingStore(),
      unsub: [],
      orgaUnsub: [],
      routerLoadToLate: false,
      currentOrga: null,
      organisations: [],
      config: {},
      prices: {},
      pigMode: false
    }
  },
  created() {
    window.showPig = () => {
      localStorage.pigMode = true
      window.location.reload()
    }
    window.hidePig = () => {
      localStorage.pigMode = false
      window.location.reload()
    }
    this.updatePigMode()
    getAuth().onAuthStateChanged(user => {
      if (user) {
        this.store.user = user
        let login = true
        this.unsub.push(Profile.listenById(user.uid, async profile => {
          if (profile) {
            this.store.profile = profile

            const meta = this.$route.meta
            if (
              meta.authLevel && meta.authLevel.SuperAdmin && this.store.profile.role !== 'SuperAdmin' ||
              meta.authLevel && meta.authLevel.Dev && !this.store.profile.dev
            ) {
              this.$router.push('/401')
            }

            if (login) {
              // used to not triger the method on profile update (listen)
              login = false
              profile.saveLastLogin()
            }

            // organisations
            this.orgaUnsub.forEach(unsub => {
              if (typeof unsub === 'function') unsub()
            })
            this.orgaUnsub = []
            if (this.store.profile.role === 'SuperAdmin') {
              this.orgaUnsub.push(Organisation.listenAll(organisations => this.initOrganisations(organisations)))
            } else if (this.store.profile.organisations) {
              const ids = Object.keys(this.store.profile.organisations)
              this.orgaUnsub.push(Organisation.listenByArrayOfIds(ids, organisations => this.initOrganisations(organisations)))
            }

          } else {
            this.init()
            this.$router.push('/login')
          }
        }))
      } else {
        this.init()
        if (this.$route.meta.needAccount || this.$route.meta.needAdmin || this.$route.meta.authLevel) {
          this.$router.push('/login')
        }
        if (this.$route.name === undefined) {
          this.routerLoadToLate = true
        }
      }
    })


    this.unsub.push(Config.listenAll(config => {
      this.config = config
    }))
    this.unsub.push(CoinsPrice.listenAll(coinsPrices => {
      this.prices.coins = coinsPrices.sort((a,b) => a.quantity - b.quantity)
    }))
    this.unsub.push(AppPrice.listenAll(appPrices => {
      this.prices.app = appPrices.sort((a,b) => b.priceInfo.app - a.priceInfo.app)
    }))
    this.unsub.push(AppPrice.listenArchiving(archiving => {
      this.archivingStore.autoArchivingDuration = archiving
    }))
  },
  beforeUnmount() {
    this.unsub.forEach(unsub => {
      if (typeof unsub === 'function') unsub()
    })
    this.orgaUnsub.forEach(unsub => {
      if (typeof unsub === 'function') unsub()
    })
  },
  watch: {
    /**
     * -> network -> fast 3G / low 3G / etc..
     * created() {
     *   c onsole.log(this.$route.name, this.$route)
     * }
     * -> refresh -> this.$route.name === undefined
     */
    $route(newVal) {
      if (this.routerLoadToLate && newVal.meta.needAccount) {
        if (this.store.user === null) {
          this.$router.push('/login')
        }
      }
    }
  },
  methods: {
    initOrganisations(organisations) {
      this.organisations = organisations.sort((a,b) => a.name >= b.name ? 1 : -1)
      if (this.organisations.length > 0) {
        this.currentOrga = this.organisations.find(orga => orga.id === this.store.profile.currentOrganisation)
        if (!this.currentOrga) {
          this.store.profile.currentOrganisation = this.organisations[0].id
          this.currentOrga = this.organisations[0]
          this.store.profile.save()
        }
      }
      if (this.store.profile.role !== 'SuperAdmin' && this.$route.meta.needAdmin && this.store.profile.organisations[this.currentOrga.id].role !== 'Admin') {
        this.$router.push('/401')
      }
    },
    isAdmin() {
      return this.store.profile.role === 'SuperAdmin' || (this.currentOrga && this.store.profile.organisations[this.currentOrga.id].role === 'Admin')
    },
    init() {
      this.store.user = null
      this.store.profile = null
      this.currentOrga = null
      this.organisations = []
    },
    goToStore() {
      if(this.store.profile.role === 'SuperAdmin'){
        this.$router.push('/store')
      }
    },
    updatePigMode(){
      this.pigMode = localStorage.pigMode == "true"
    },
  }
}
</script>

<style lang="scss" scoped>
.route-enter-from {
  opacity: 0;
  transform: translateY(50%);
}

.route-enter-active,
.route-leave-active {
  transition: opacity 200ms ease-out, transform 250ms ease-out;
}

.route-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}

.custom-row {
  height: 100%;
  display: flex;
  &:has(.nav) {
    display: grid;
    grid-template-columns: auto 1fr;
  }
}

.grid-col {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.app {
  overflow: auto;
}
</style>
